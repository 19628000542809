import { memo, useMemo } from 'react';
import { Group, Popover, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { TextProps } from '@mantine/core';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

type Props = {
	status: string;
	isOrderMarkedForCancellation?: boolean;
	size?: TextProps['size'];
};

export const OrderStatusDisplay = memo(
	({ status, isOrderMarkedForCancellation = false, size }: Props) => {
		const { t } = useTranslation('order');
		const [opened, { close, open }] = useDisclosure(false);
		const theme = useMantineTheme();
		const statusColor = useMemo(
			() => theme.other.getOrderStatusColor(status, isOrderMarkedForCancellation, t),
			[theme.other, status, isOrderMarkedForCancellation, t],
		);

		return (
			<>
				<Text c={statusColor} size={size} span>
					<Group wrap='nowrap' align='center'>
						{status === t('orderStatus.orderReview') && (
							<icons.Warning size={size} c='yellow.7' />
						)}
						{status}
					</Group>
				</Text>
				{isOrderMarkedForCancellation && (
					<Popover withArrow opened={opened}>
						<Popover.Target>
							<icons.Warning
								onMouseEnter={open}
								onMouseLeave={close}
								size={size}
								c='yellow.7'
							/>
						</Popover.Target>
						<Popover.Dropdown>
							<Text size={size} span>
								{t('orderDetails.labels.cancellationRequestedTooltip')}
							</Text>
						</Popover.Dropdown>
					</Popover>
				)}
			</>
		);
	},
);
OrderStatusDisplay.displayName = 'OrderStatusDisplay';
