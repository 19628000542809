import { z } from 'zod';

import { countryCodeSchema, dateTimeSchema } from '@apple/utils/globalization';
import type { CountryCode } from '@apple/utils/globalization';

export interface PurchaseOrder {
	status: string;
	purchaseOrderNumber: string;
	salesOrderNumber: string;
	orderedBy: number;
	orderedByEmailAddress: string;
	orderedByLastName: string;
	orderedByFirstName: string;
	orderDate: string | Date;
	updatedDate: string | Date;
	shippingDate: string | Date | null;
	customerReferenceNumber: string | null;
	userRole: string;
	customerCode: string;
	customerGroupName: string;
	initialOrderComment: string | null;
	appleId: string | null;
	shippingType: string;
	availableShippingOptions: ShippingOption[];
	selectedShippingOptionId: number;
	selectedShippingOptionPrice: string;
	isOrderMarkedForCancellation: boolean;
	isOrderCancellable: boolean;
	isOrderEditable: boolean;
	orderTotal: string;
	referenceNumber: string | null;
	customerId: number;
	locationId: string | null;
	customerGroupId: number;
}

export interface ShippingOption {
	id: number;
	displayName: string;
	price: string | null;
}

export interface OrderLineBase {
	quantity: number;
	approvalRequired: boolean;
	approvalRequiredText: string | null;
	formattedUnitAmount: string | null;
	imageUrl: string | null;
	isInventoryItem: boolean;
	parentPosition: number | null;
	position: number;
	quantityLimit: number;
	shippedQuantity: number;
	sku: string;
	status: string;
	statusList: PurchaseOrderLineStatus[];
	title: string;
	unitAmount: number;
	unitOfMeasure: string;
	unitsPerCarton: string;
	approvalReasons: string[];
}
export interface OrderLineItem extends OrderLineBase {
	formattedTotalAmount: string;
	totalAmount: number;
}

export interface PurchaseOrderLine extends OrderLineBase {
	available?: boolean | null;
	cancellationReason?: string | null;
	externalLineItemKey?: string | null;
	totalAmount: string;
}

export interface PurchaseOrderLineStatus {
	status: string;
	internalStatus: number;
	quantity: number;
	trackingUrl: string | null;
	trackingNumber: string | null;
	carrierName: string | null;
	shipDate?: string | Date | null;
}

// TODO: Evaluate address models and see if these can be unified
export interface Address {
	displayName: string | null;
	recipient: string;
	phoneNumber: string;
	attentionTo: string | null;
	line1: string;
	line2: string | null;
	line3: string | null;
	city: string;
	state: string | null;
	postalCode: string | null;
	countryCode: CountryCode;
	countryDisplayName: string;
}

// TODO: Evaluate address models and see if these can be unified
export interface InternationalAddress {
	internationalRecipient: string;
	internationalAttentionTo: string | null;
	internationalLine1: string;
	internationalLine2: string | null;
	internationalLine3: string | null;
	internationalCity: string;
	internationalState: string | null;
	internationalPostalCode: string | null;
}

export interface OrderAgainRequest {
	locationId: string;
	customerGroupId: number;
	isLineAvailable: boolean;
	sku: string;
	quantity: number;
}
export interface Comment {
	id: string;
	message: string;
	createdOn: string | Date;
	createdBy: Commenter;
}
export interface Commenter {
	userId: number;
	name: string;
}
export interface Message {
	message: string;
}
export interface ItemQuantity {
	itemId: string;
	quantity: number;
}

export const shippingOptionSchema = z.object({
	id: z.number(),
	displayName: z.string(),
	price: z.string().nullable(),
}) satisfies z.ZodType<ShippingOption>;

export const addressSchema = z.object({
	displayName: z.string().nullable(),
	recipient: z.string(),
	phoneNumber: z.string(),
	attentionTo: z.string().nullable(),
	line1: z.string(),
	line2: z.string().nullable(),
	line3: z.string().nullable(),
	city: z.string(),
	state: z.string().nullable(),
	postalCode: z.string().nullable(),
	countryCode: countryCodeSchema,
	countryDisplayName: z.string(),
}) satisfies z.ZodType<Address>;

export const internationalAddressSchema = z.object({
	internationalRecipient: z.string(),
	internationalAttentionTo: z.string().nullable(),
	internationalLine1: z.string(),
	internationalLine2: z.string().nullable(),
	internationalLine3: z.string().nullable(),
	internationalCity: z.string(),
	internationalState: z.string().nullable(),
	internationalPostalCode: z.string().nullable(),
}) satisfies z.ZodType<InternationalAddress>;

export type InternationalAddressResponse =
	| { hasInternationalAddress: false }
	| ({ hasInternationalAddress: true } & InternationalAddress);

const internationalAddressResponseSchema = z.discriminatedUnion('hasInternationalAddress', [
	z.object({ hasInternationalAddress: z.literal(false) }),
	internationalAddressSchema.extend({ hasInternationalAddress: z.literal(true) }),
]) satisfies z.ZodType<InternationalAddressResponse>;

export const purchaseOrderSchema = z.object({
	purchaseOrderNumber: z.string(),
	salesOrderNumber: z.string(),
	orderedBy: z.number(),
	orderedByEmailAddress: z.string(),
	orderedByLastName: z.string(),
	orderedByFirstName: z.string(),
	// Can't use orderStatusSchema here because the api returns the localized value...
	status: z.string(),
	orderDate: dateTimeSchema,
	updatedDate: dateTimeSchema,
	shippingDate: dateTimeSchema.nullable(),
	customerReferenceNumber: z.string().nullable(),
	userRole: z.string(),
	customerCode: z.string(),
	customerGroupName: z.string(),
	initialOrderComment: z.string().nullable(),
	appleId: z.string().nullable(),
	shippingType: z.string(),
	availableShippingOptions: z.array(shippingOptionSchema),
	selectedShippingOptionId: z.number(),
	selectedShippingOptionPrice: z.string(),
	isOrderMarkedForCancellation: z.boolean(),
	isOrderCancellable: z.boolean(),
	isOrderEditable: z.boolean(),
	orderTotal: z.string(),
	referenceNumber: z.string().nullable(),
	customerId: z.number(),
	locationId: z.string().nullable(),
	customerGroupId: z.number(),
}) satisfies z.ZodType<PurchaseOrder>;

export type PurchaseOrderResponse = PurchaseOrder & Address & InternationalAddressResponse;

export const purchaseOrderResponseSchema = purchaseOrderSchema
	.and(addressSchema)
	.and(internationalAddressResponseSchema) satisfies z.ZodType<PurchaseOrderResponse>;

export const purchaseOrderLineStatusSchema = z.object({
	status: z.string(),
	internalStatus: z.number(),
	quantity: z.number(),
	trackingUrl: z.string().nullable(),
	trackingNumber: z.string().nullable(),
	carrierName: z.string().nullable(),
	shipDate: dateTimeSchema.optional().nullable(),
}) satisfies z.ZodType<PurchaseOrderLineStatus>;

export const purchaseOrderLineSchema = z.object({
	parentPosition: z.number().nullable(),
	position: z.number(),
	sku: z.string(),
	title: z.string(),
	quantity: z.number(),
	status: z.string(),
	unitsPerCarton: z.string(),
	isInventoryItem: z.boolean(),
	statusList: z.array(purchaseOrderLineStatusSchema),
	approvalRequired: z.boolean(),
	shippedQuantity: z.number(),
	approvalRequiredText: z.string().nullable(),
	quantityLimit: z.number(),
	unitOfMeasure: z.string(),
	unitAmount: z.number(),
	formattedUnitAmount: z.string().nullable(),
	totalAmount: z.string(),
	imageUrl: z.string().nullable(),
	cancellationReason: z.string().optional().nullable(),
	externalLineItemKey: z.string().optional().nullable(),
	available: z.boolean().optional(),
	approvalReasons: z.array(z.string()),
}) satisfies z.ZodType<PurchaseOrderLine>;

export const orderLineItemSchema = purchaseOrderLineSchema
	.extend({
		totalAmount: z.number(),
		formattedTotalAmount: z.string(),
	})
	.omit({
		cancellationReason: true,
		externalLineItemKey: true,
		available: true,
	}) satisfies z.ZodType<OrderLineItem>;

export const messageSchema = z.object({
	message: z.string(),
}) satisfies z.ZodType<Message>;

export const commenterSchema = z.object({
	userId: z.number(),
	name: z.string(),
}) satisfies z.ZodType<Commenter>;

export const commentSchema = z.object({
	id: z.string(),
	message: z.string(),
	createdOn: dateTimeSchema,
	createdBy: commenterSchema,
}) satisfies z.ZodType<Comment>;

export const orderAgainRequestSchema = z.object({
	locationId: z.string(),
	customerGroupId: z.number(),
	isLineAvailable: z.boolean(),
	sku: z.string(),
	quantity: z.number(),
}) satisfies z.ZodType<OrderAgainRequest>;

export const itemQuantitySchema = z.object({
	itemId: z.string(),
	quantity: z.number(),
}) satisfies z.ZodType<ItemQuantity>;
