import { z } from 'zod';

import {
	countryCodeSchema,
	countryWithCulturesSchema,
	currencyCodeSchema,
} from '@apple/utils/globalization';
import { weekdaySchema } from '@apple/utils/globalization/components/days-of-week/model';
import type { CountryWithCultures } from '@apple/utils/globalization';
import type { Weekday } from '@apple/utils/globalization/components/days-of-week/model';

export enum NotificationType {
	ProductLifecycle = 0,
	SecondLevelApproval = 1,
	// The only difference between Retail and Channel is the enum value of 1.
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	DistroPlacement = 1,
	EventConfiguration = 301,
	EMKPickDate = 302,
	EMKShipDate = 303,
}

const notificationTypeSchema = z.nativeEnum(NotificationType);

/**
 * @see Manzanita.Web.Models.PlantDistributionGroupModel
 */
export interface PlantDistributionGroupModel {
	warehouseLocationId: number;
	notificationTypeId: NotificationType;
	recipients: string;
}

export const PlantDistributionGroupModelSchema = z.object({
	warehouseLocationId: z.number(),
	notificationTypeId: notificationTypeSchema,
	recipients: z.string(),
}) satisfies z.ZodType<PlantDistributionGroupModel>;

export const plantSchema = z.object({
	warehouseLocationId: z.string().transform(Number),
	name: z.string(),
	description: z.string(),
	warehouseCode: z.string(),
	abbreviation: z.string(),
	currencyCode: currencyCodeSchema,
	countryCodes: z.array(countryCodeSchema),
});

/**
 * @see Manzanita.DataModels.Plant
 */
export interface PlantListing {
	warehouseLocationId: string;
	description: string;
	code: string;
	name: string;
	abbreviation: string;
	salesOrganization: string;
	currency: string;
	shipmentDays: Weekday[];
	timeZone: string;
	orderCutoffHour: number;
	countries: string[];
	countryCode: string;
	distributionGroups: PlantDistributionGroupModel[];
}

export const plantListingSchema = z.object({
	warehouseLocationId: z.string(),
	description: z.string(),
	code: z.string(),
	name: z.string(),
	abbreviation: z.string(),
	salesOrganization: z.string(),
	currency: z.string(),
	shipmentDays: z.array(weekdaySchema),
	timeZone: z.string(),
	orderCutoffHour: z.number(),
	countries: z.array(z.string()),
	countryCode: z.string(),
	distributionGroups: z.array(PlantDistributionGroupModelSchema),
}) satisfies z.ZodType<PlantListing>;

export interface PlantsFilters {
	name?: string;
	code?: string;
	abbreviation?: string;
	salesOrganization?: string;
	currency?: string;
}
export const plantsFiltersSchema = z.object({
	name: z.string().optional(),
	code: z.string().optional(),
	abbreviation: z.string().optional(),
	salesOrganization: z.string().optional(),
	currency: z.string().optional(),
}) satisfies z.ZodType<PlantsFilters>;

/**
 * Model from the validation api
 */
export interface PlantRequest {
	plantCountry: string;
	shipmentDays: Weekday[];
	description: string;
	plantCurrency: string;
	timeZone: string;
	plantCode: string;
	plantName: string;
	plantAbbreviation: string;
	salesOrganization: string;
	destinationCountry: string[];
	distributionGroups: PlantDistributionGroupModel[];
	warehouseLocationId: string;
	orderCutoffHour: number;
}

export const plantRequestSchema = z.object({
	plantCountry: plantListingSchema.shape.countryCode,
	shipmentDays: plantListingSchema.shape.shipmentDays,
	description: plantListingSchema.shape.description,
	plantCurrency: plantListingSchema.shape.currency,
	timeZone: plantListingSchema.shape.timeZone,
	plantCode: plantListingSchema.shape.code,
	plantName: plantListingSchema.shape.name,
	plantAbbreviation: plantListingSchema.shape.abbreviation,
	salesOrganization: plantListingSchema.shape.salesOrganization,
	destinationCountry: plantListingSchema.shape.countries,
	distributionGroups: plantListingSchema.shape.distributionGroups,
	warehouseLocationId: plantListingSchema.shape.warehouseLocationId,
	orderCutoffHour: plantListingSchema.shape.orderCutoffHour,
}) satisfies z.ZodType<PlantRequest>;

export interface PlantDistributionGroup {
	notificationTypeId: NotificationType;
	recipients: string[];
}

const plantDistributionGroupSchema = z.object({
	notificationTypeId: notificationTypeSchema,
	recipients: z.array(z.string()),
}) satisfies z.ZodType<PlantDistributionGroup>;

export interface PlantFormData
	extends Omit<PlantRequest, 'destinationCountry' | 'distributionGroups'> {
	destinationCountry: CountryWithCultures[];
	distributionGroups: PlantDistributionGroup[];
}

export const plantFormDataSchema = plantRequestSchema.extend({
	destinationCountry: z.array(countryWithCulturesSchema).min(1),
	distributionGroups: z.array(plantDistributionGroupSchema),
}) satisfies z.ZodType<PlantFormData>;
